<template>
  <v-container fluid class="plans_subscriptions_search">
    <PlansSubscriptionsSearch @search="() => $refs.table.refresh()" />
    <BaseTable
      ref="table"
      title="Sottoscrizioni"
      :headers="headers"
      :actions="actions"
      :exportable="canUser('plans_subscriptions', 'download')"
      module="plansSubscriptions"
    >
      <template v-slot:item.plan.code="{ item }">
        <v-chip color="primary" label>{{
          item | get(['plan', 'code'], '-')
        }}</v-chip>
      </template>

      <template v-slot:item.customer.common_name="{ item }">
        <router-link
          class="customer_name text-decoration-none d-inline-flex align-center"
          :to="{ name: 'customers_detail', params: { id: item.customer_id } }"
        >
          <strong class="font-weight-bold">
            {{ item | get(['customer', 'common_name'], '') }}
          </strong>

          <v-icon small right class="ml-1 link_icon">open_in_new</v-icon>
        </router-link>
      </template>

      <template v-slot:item.status="{ item }">
        {{ statusMap[item.status] }}
      </template>
      <template v-slot:item.code="{ item }">
        <v-chip label>{{ item.code }}</v-chip>
      </template>

      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | momentOr('DD/MM/YYYY', '-') }}
      </template>

      <template v-slot:item.date_expiration="{ item }">
        {{ item.date_expiration | momentOr('DD/MM/YYYY', '-') }}
      </template>

      <template v-slot:item.options="{ item }">
        <PlansOptionsQuickView :item="item" />
      </template>
      <template v-slot:footer>
        <v-dialog
          v-model="dialog"
          scrollable
          max-width="75%"
          :fullscreen="$vuetify.breakpoint.mobile"
          :retain-focus="false"
          @click:outside="modalClose"
        >
          <v-card>
            <v-card-title>Sottoscrizione Piano</v-card-title>
            <v-card-text>
              <PlansSubscriptionsForm
                :title="null"
                :edit="editMode"
                @submitted="onSubmitted"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapGetters, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import PlansOptionsQuickView from '@components/plans/PlansOptionsQuickView.vue'
import PlansSubscriptionsForm from '@components/plans/PlansSubscriptionsForm.vue'
import PlansSubscriptionsSearch from '@components/plans_subscriptions/PlansSubscriptionsSearch.vue'

export default {
  name: 'TicketSearch',
  page: {
    title: 'Cerca sottoscrizioni',
  },
  components: {
    BaseTable,
    PlansOptionsQuickView,
    PlansSubscriptionsForm,
    PlansSubscriptionsSearch,
  },

  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Cliente',
          value: 'customer.common_name',
        },
        {
          text: 'Identificativo',
          value: 'code',
        },
        {
          text: 'Codice Piano',
          align: 'start',
          value: 'plan.code',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Canale',
          value: 'channel',
        },
        {
          text: 'Opzioni',
          value: 'options',
        },
        {
          text: 'Data Avvio',
          value: 'date_start',
        },
        {
          text: 'Data Scadenza',
          value: 'date_expiration',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('plans_subscriptions', 'update'),
          handler: this.openModal,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters('plansSubscriptions', ['statusMap']),
  },
  mounted() {
    // This is used for the table rows not for the download
    this.setScopes(['plan', 'customer'])
  },
  beforeDestroy() {
    this.setScopes([])
  },
  methods: {
    ...mapMutations('plansSubscriptions', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>

<style lang="scss" scoped>
.plans_subscriptions_search {
  .customer_name {
    &:hover {
      .link_icon {
        transform: scale(1);
      }
    }
    .link_icon {
      transform: scale(0);
    }
  }
}
</style>
