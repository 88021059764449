var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSectionSearch',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"type":"select","name":"Stato","hint":"Ricerca per stato","values":[
        { value: 'active', text: 'Attiva' },
        { value: 'expired', text: 'Scaduta' },
        { value: 'waiting', text: 'In attesa di validazione' },
        { value: 'cancelled', text: 'Cancellata' } ],"cols":3,"dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('FormItem',{attrs:{"type":"select","name":"Canale","hint":"Ricerca per Canale","values":[
        { value: 'direct', text: 'Diretta' },
        { value: 'digitalstore', text: 'Digital Store' },
        { value: 'appdirect', text: 'AppDirect' } ],"cols":3,"dense":""},model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=$$v},expression:"channel"}}),(!_vm.currentCustomerId)?_c('FormCustomers',{attrs:{"name":"Filtra Cliente","dense":"","cols":"3"},model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=$$v},expression:"customerId"}}):_vm._e(),(!_vm.currentCustomerId)?_c('FormPlans',{attrs:{"dense":"","multi":true},model:{value:(_vm.planId),callback:function ($$v) {_vm.planId=$$v},expression:"planId"}}):_vm._e(),_c('FormItem',{attrs:{"type":"datepicker","name":"Data di attivazione","hint":"Ricerca per data di attivazione","cols":3,"dense":"","range":""},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}}),_c('FormItem',{attrs:{"type":"datepicker","name":"Data di scadenza","hint":"Ricerca per data di scadenza","cols":3,"dense":"","range":""},model:{value:(_vm.dateExpiration),callback:function ($$v) {_vm.dateExpiration=$$v},expression:"dateExpiration"}}),_c('FormItem',{attrs:{"type":"text","name":"Identificativo","hint":"Ricerca per codice identificativo","cols":3,"dense":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }