<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="status"
        type="select"
        name="Stato"
        hint="Ricerca per stato"
        :values="[
          { value: 'active', text: 'Attiva' },
          { value: 'expired', text: 'Scaduta' },
          { value: 'waiting', text: 'In attesa di validazione' },
          { value: 'cancelled', text: 'Cancellata' },
        ]"
        :cols="3"
        dense
      />
      <FormItem
        v-model="channel"
        type="select"
        name="Canale"
        hint="Ricerca per Canale"
        :values="[
          { value: 'direct', text: 'Diretta' },
          { value: 'digitalstore', text: 'Digital Store' },
          { value: 'appdirect', text: 'AppDirect' },
        ]"
        :cols="3"
        dense
      />
      <FormCustomers
        v-if="!currentCustomerId"
        v-model="customerId"
        name="Filtra Cliente"
        dense
        cols="3"
      />
      <FormPlans
        v-if="!currentCustomerId"
        v-model="planId"
        dense
        :multi="true"
      />
      <FormItem
        v-model="dateStart"
        type="datepicker"
        name="Data di attivazione"
        hint="Ricerca per data di attivazione"
        :cols="3"
        dense
        range
      />
      <FormItem
        v-model="dateExpiration"
        type="datepicker"
        name="Data di scadenza"
        hint="Ricerca per data di scadenza"
        :cols="3"
        dense
        range
      />
      <FormItem
        v-model="code"
        type="text"
        name="Identificativo"
        hint="Ricerca per codice identificativo"
        :cols="3"
        dense
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormPlans from '@components/forms/FormPlans.vue'

import { createHelpers } from 'vuex-map-fields'
import { mapMutations } from 'vuex'
import FormCustomers from '@components/forms/FormCustomers.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields: plansSubscriptionsFilters } = createHelpers({
  getterType: 'plansSubscriptions/getFiltersFields',
  mutationType: 'plansSubscriptions/SET_FILTER_FIELDS',
})

export default {
  name: 'PlansSubscriptionsSearch',
  components: {
    FormCustomers,
    FormPlans,
    FormItem,
    BaseSectionSearch,
  },
  computed: {
    ...authComputed,
    // This is used for the subscriptions search
    ...plansSubscriptionsFilters({
      planId: 'plan_id',
      code: 'code',
      status: 'status',
      channel: 'channel',
      dateStart: 'date_start',
      dateExpiration: 'date_expiration',
      customerId: 'customer_id',
    }),
  },
  beforeDestroy() {
    this.resetSubscriptionsFilters()
  },
  methods: {
    ...mapMutations('plansSubscriptions', {
      resetSubscriptionsFilters: 'RESET_FILTER_FIELDS',
    }),

    onSubmit() {
      this.$emit('search')
    },

    onReset() {
      this.resetSubscriptionsFilters()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
