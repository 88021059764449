<template>
  <FormItem
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    :label="label || name"
    :name="name"
    :persistent-hint="persistentHint"
    :rules="rules"
    :type="multi ? 'autocomplete' : 'select'"
    :value="value"
    :values="channels"
    @input="onInput"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { debounce } from 'lodash'

export default {
  name: 'FormChannels',
  components: { FormItem },
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Canali di Ingaggio',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: '',
      required: false,
      type: String,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 3,
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    model: null,
    channels: [
      { text: 'Portale Web', value: 'web' },
      { text: 'Applicazione Mobile', value: 'app' },
      { text: 'Mail di richiesta', value: 'mail' },
      { text: 'Chiamata in-bound', value: 'telefono' },
    ],
  }),
  methods: {
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
