var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"plans_subscriptions_search",attrs:{"fluid":""}},[_c('PlansSubscriptionsSearch',{on:{"search":function () { return _vm.$refs.table.refresh(); }}}),_c('BaseTable',{ref:"table",attrs:{"title":"Sottoscrizioni","headers":_vm.headers,"actions":_vm.actions,"exportable":_vm.canUser('plans_subscriptions', 'download'),"module":"plansSubscriptions"},scopedSlots:_vm._u([{key:"item.plan.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(_vm._f("get")(item,['plan', 'code'], '-')))])]}},{key:"item.customer.common_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"customer_name text-decoration-none d-inline-flex align-center",attrs:{"to":{ name: 'customers_detail', params: { id: item.customer_id } }}},[_c('strong',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("get")(item,['customer', 'common_name'], ''))+" ")]),_c('v-icon',{staticClass:"ml-1 link_icon",attrs:{"small":"","right":""}},[_vm._v("open_in_new")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusMap[item.status])+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.code))])]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.date_start,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.date_expiration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.date_expiration,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('PlansOptionsQuickView',{attrs:{"item":item}})]}},{key:"footer",fn:function(){return [_c('v-dialog',{attrs:{"scrollable":"","max-width":"75%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Sottoscrizione Piano")]),_c('v-card-text',[_c('PlansSubscriptionsForm',{attrs:{"title":null,"edit":_vm.editMode},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }