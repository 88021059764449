<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-magnify
      </v-icon>
    </template>

    <v-card>
      <v-card-text v-if="!activeOptions.length" align="center">
        <v-icon x-large class="mb-3">mdi-playlist-remove</v-icon>
        Non ci sono opzioni attive per questa sottoscrizione.
      </v-card-text>

      <v-list>
        <v-list-item
          v-if="
            (item.max_ticket_per_cycle || item.max_ticket_per_duration) &&
              (item.max_ticket_per_cycle > -1 ||
                item.max_ticket_per_duration > -1)
          "
        >
          <v-list-item-content>
            <v-list-item-title>
              <strong
                v-if="
                  item.max_ticket_per_cycle && item.max_ticket_per_cycle > -1
                "
              >
                {{ item.max_ticket_per_cycle }} per ciclo fatturazione
              </strong>
              <strong
                v-if="
                  item.max_ticket_per_duration &&
                    item.max_ticket_per_duration > -1
                "
              >
                {{ item.max_ticket_per_duration }} per durata totale
              </strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Numero massimo ticket
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="option in activeOptions" :key="option.name">
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ option.name }}</strong>
            </v-list-item-title>

            <v-list-item-subtitle v-if="option.devices_num">
              Dispositivi: <strong>{{ option.devices_num }}</strong>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="option.mdm">
              MDM in Uso: <strong>{{ option.mdm }}</strong>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="option.devices_brand">
              Modello:
              <strong>
                {{ option.devices_brand }} {{ option.devices_model }}
              </strong>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="option.app">
              Applicativo: <strong>{{ option.app }}</strong>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="option.partner">
              Partner: <strong>{{ option.partner }}</strong>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text small @click="menu = false">
          Chiudi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'PlansOptionsQuickView',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    options() {
      if (this.item) return this.item.options || {}
      return {}
    },
    activeOptions() {
      return Object.values(this.options).filter((opt) => opt?.enabled)
    },
  },
}
</script>

<style scoped></style>
