<template>
  <Form
    :hide-reset="edit"
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h3 class="my-3">Informazioni Piano</h3>

    <v-row>
      <FormPlans v-model="plan_id" />

      <FormItem
        v-model="status"
        rules="required"
        type="select"
        :values="[
          { value: 'active', text: 'Attiva' },
          { value: 'expired', text: 'Scaduta' },
          { value: 'waiting', text: 'In attesa di validazione' },
          { value: 'cancelled', text: 'Cancellata' },
        ]"
        name="Stato Sottoscrizione"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
      />

      <FormItem
        :value="date_start"
        rules="required"
        type="datepicker"
        name="Avvio Sottoscrizione"
        :cols="3"
        :sm="3"
        :md="3"
        :lg="2"
        @input="dateStartChange"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="expirationBridge"
        type="select"
        name="Termina in"
        :values="expirationOptions"
        :cols="3"
        :sm="3"
        :md="3"
        :lg="2"
      />

      <FormItem
        v-model="date_expiration"
        type="datepicker"
        name="Termine Sottoscrizione"
        clearable
        :readonly="expirationBridge !== 'other'"
        :cols="3"
        :sm="3"
        :md="3"
        :lg="2"
      />

      <FormCustomersUsers
        v-model="customer_user_reference_id"
        :customer_id="customer_id"
        :customer_user_id="customer_user_reference_id"
        label="Referente Aziendale"
        dense
        clearable
        :cols="3"
        :sm="3"
        :md="3"
        :lg="2"
      />
    </v-row>

    <h3 class="my-3">
      Durata e numeriche
      <small>(Inserire -1 per non imporre limiti)</small>
    </h3>
    <v-row>
      <FormItem
        v-model="max_users"
        type="number"
        rules="required"
        name="Max Utenti"
        hint="Il numero massimo di utenti associabili al piano."
        persistent-hint
        :cols="3"
        :lg="2"
      />

      <FormItem
        v-model="max_devices"
        type="number"
        rules="required"
        name="Max Dispositivi"
        hint="Il numero massimo di dispositivi associabili al piano."
        persistent-hint
        :cols="3"
        :lg="2"
      />

      <FormItem
        v-if="false"
        v-model="max_devices_per_user"
        type="number"
        name="Max Dispositivi per Utente"
        :cols="3"
        :lg="2"
      />

      <FormItem
        v-if="false"
        v-model="max_ticket_per_user"
        type="number"
        name="Max Ticket per Utente"
        :cols="3"
        :lg="2"
      />

      <FormItem
        v-model="max_ticket_per_cycle"
        type="number"
        name="Max Ticket per Ciclo"
        :cols="3"
        :lg="2"
      />

      <FormItem
        v-if="false"
        v-model="max_ticket_per_month"
        type="number"
        name="Max Ticket per Mese"
        :cols="3"
        :lg="2"
      />

      <FormItem
        v-model="max_ticket_per_duration"
        type="number"
        name="Max Ticket per Durata"
        :cols="3"
        :lg="2"
      />
    </v-row>

    <h3 class="my-3"> Opzioni </h3>

    <v-row>
      <FormWeekDays
        v-model="weekdays"
        name="Giorni di servizio"
        :cols="4"
        :md="4"
        :lg="4"
      />
      <FormTimeRange
        v-model="time_range"
        name="Orari Servizio"
        :cols="3"
        :md="3"
        :lg="3"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="notes"
        name="Note"
        type="textarea"
        :cols="5"
        :md="5"
        :lg="5"
        :rows="3"
      />

      <FormItem v-model="pin" name="PIN" :cols="2" :md="2" :lg="2" />
    </v-row>

    <v-divider class="mb-6" />

    <v-row>
      <FormItem
        v-model="help_desk_classico_enabled"
        type="switch"
        :name="help_desk_classico_name"
        dense
        :cols="3"
      />

      <FormItem
        v-model="help_desk_classico_devices_num"
        dense
        type="number"
        name="Dispositivi"
        :disabled="!help_desk_classico_enabled"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="1"
        :xl="1"
      />

      <FormChannels
        v-model="help_desk_classico_channels"
        :disabled="!help_desk_classico_enabled"
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
        :xl="3"
        dense
        multi
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="help_desk_kasko_enabled"
        type="switch"
        :name="help_desk_kasko_name"
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
        :xl="3"
        dense
      />

      <FormItem
        v-model="help_desk_kasko_devices_num"
        type="number"
        name="Dispositivi"
        :disabled="!help_desk_kasko_enabled"
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
        dense
      />

      <FormChannels
        v-model="help_desk_kasko_channels"
        :cols="3"
        multi
        :disabled="!help_desk_kasko_enabled"
        dense
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="help_desk_mdm_enabled"
        type="switch"
        :name="help_desk_mdm_name"
        dense
        :cols="3"
      />

      <FormItem
        v-model="help_desk_mdm_devices_num"
        type="number"
        name="Dispositivi"
        :disabled="!help_desk_mdm_enabled"
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
        dense
      />

      <FormMDM
        v-model="help_desk_mdm_mdm"
        :disabled="!help_desk_mdm_enabled"
        dense
      />

      <FormChannels
        v-model="help_desk_mdm_channels"
        :disabled="!help_desk_mdm_enabled"
        :cols="3"
        multi
        dense
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="help_desk_app_enabled"
        type="switch"
        :name="help_desk_app_name"
        :cols="3"
        dense
      />

      <FormItem
        v-model="help_desk_app_devices_num"
        type="number"
        name="Dispositivi"
        :disabled="!help_desk_app_enabled"
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
        dense
      />

      <FormItem
        v-model="help_desk_app_app"
        name="Applicativo"
        :disabled="!help_desk_app_enabled"
        :cols="4"
        dense
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="enrollment_enabled"
        type="switch"
        :name="enrollment_name"
        :cols="3"
        dense
      />
      <FormItem
        v-model="enrollment_devices_num"
        type="number"
        name="Dispositivi"
        :disabled="!enrollment_enabled"
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
        dense
      />
      <FormItem
        v-model="enrollment_devices_brand"
        name="Marca"
        :cols="2"
        :disabled="!enrollment_enabled"
        dense
      />
      <FormItem
        v-model="enrollment_devices_model"
        name="Modello"
        :cols="2"
        :disabled="!enrollment_enabled"
        dense
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="kitting_enabled"
        type="switch"
        :name="kitting_name"
        :cols="3"
        dense
      />
      <FormItem
        v-model="kitting_devices_num"
        type="number"
        name="Dispositivi"
        :readonly="!kitting_enabled"
        dense
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      />
      <FormItem
        v-model="kitting_pool_coverage"
        type="number"
        name="% Pool coperto"
        :readonly="!kitting_enabled"
        dense
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      />
      <FormItem
        v-model="kitting_devices_brand"
        name="Marca"
        :readonly="!kitting_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="kitting_devices_model"
        name="Modello"
        :disabled="!kitting_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="kitting_partner"
        name="Partner"
        :disabled="!kitting_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="kasko_enabled"
        type="switch"
        :name="kasko_name"
        :cols="3"
        dense
      />
      <FormItem
        v-model="kasko_devices_num"
        type="number"
        name="Dispositivi"
        :readonly="!kasko_enabled"
        dense
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      />
      <FormItem
        v-model="kasko_pool_coverage"
        type="number"
        name="% Pool coperto"
        :readonly="!kasko_enabled"
        dense
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      />
      <FormItem
        v-model="kasko_devices_brand"
        name="Marca"
        :readonly="!kasko_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="kasko_devices_model"
        name="Modello"
        :disabled="!kasko_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="kasko_partner"
        name="Partner"
        :disabled="!kasko_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="furto_enabled"
        type="switch"
        :name="furto_name"
        :cols="3"
        dense
      />
      <FormItem
        v-model="furto_devices_num"
        type="number"
        name="Dispositivi"
        :readonly="!furto_enabled"
        dense
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      />
      <FormItem
        v-model="furto_pool_coverage"
        type="number"
        name="% Pool coperto"
        :readonly="!furto_enabled"
        dense
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      />
      <FormItem
        v-model="furto_devices_brand"
        name="Marca"
        :readonly="!furto_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="furto_devices_model"
        name="Modello"
        :disabled="!kasko_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="furto_partner"
        name="Partner"
        :disabled="!furto_enabled"
        dense
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="scorta_calda_enabled"
        type="switch"
        :name="scorta_calda_name"
        :cols="3"
        dense
      />
      <FormItem
        v-model="scorta_calda_devices_num"
        type="number"
        name="Dispositivi"
        :disabled="!scorta_calda_enabled"
        :cols="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
        dense
      />
      <FormItem
        v-model="scorta_calda_devices_brand"
        name="Marca"
        :cols="2"
        :disabled="!scorta_calda_enabled"
        dense
      />
      <FormItem
        v-model="scorta_calda_devices_model"
        name="Modello"
        :cols="2"
        :disabled="!scorta_calda_enabled"
        dense
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormPlans from '@components/forms/FormPlans.vue'
import FormChannels from '@components/forms/FormChannels.vue'
import FormMDM from '@components/forms/FormMDM.vue'
import FormWeekDays from '@components/forms/FormWeekDays.vue'
import FormTimeRange from '@components/forms/FormTimeRange.vue'
import FormCustomersUsers from '@components/forms/FormCustomersUsers.vue'

const { mapFields } = createHelpers({
  getterType: 'plansSubscriptions/getCurrent',
  mutationType: 'plansSubscriptions/SET_CURRENT_FIELDS',
})

export default {
  name: 'PlansSubscriptionsForm',
  components: {
    FormTimeRange,
    FormCustomersUsers,
    FormWeekDays,
    FormMDM,
    FormChannels,
    FormPlans,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields([
      'status',
      'options',
      'channel',
      'payment_method',
      'date_start',
      'date_renewal',
      'date_expiration',
      'max_users',
      'max_devices',
      'max_devices_per_user',
      'max_ticket_per_user',
      'max_ticket_per_cycle',
      'max_ticket_per_month',
      'max_ticket_per_duration',
      'plan_id',
      'customer_id',
      'customer_user_reference_id'
    ]),
    ...mapFields({
      enrollment_devices_brand: 'options.enrollment.devices_brand',
      enrollment_devices_model: 'options.enrollment.devices_model',
      enrollment_devices_num: 'options.enrollment.devices_num',
      enrollment_enabled: 'options.enrollment.enabled',
      enrollment_name: 'options.enrollment.name',
      enrollment: 'options.enrollment',
      furto_devices_brand: 'options.furto.devices_brand',
      furto_devices_model: 'options.furto.devices_model',
      furto_devices_num: 'options.furto.devices_num',
      furto_enabled: 'options.furto.enabled',
      furto_name: 'options.furto.name',
      furto_partner: 'options.furto.partner',
      furto_pool_coverage: 'options.furto.pool_coverage',
      furto: 'options.furto',
      help_desk_app_app: 'options.help_desk_app.app',
      help_desk_app_devices_num: 'options.help_desk_app.devices_num',
      help_desk_app_enabled: 'options.help_desk_app.enabled',
      help_desk_app_name: 'options.help_desk_app.name',
      help_desk_app: 'options.help_desk_app',
      help_desk_classico_channels: 'options.help_desk_classico.channels',
      help_desk_classico_devices_num: 'options.help_desk_classico.devices_num',
      help_desk_classico_enabled: 'options.help_desk_classico.enabled',
      help_desk_classico_name: 'options.help_desk_classico.name',
      help_desk_classico: 'options.help_desk_classico',
      help_desk_kasko_channels: 'options.help_desk_kasko.channels',
      help_desk_kasko_devices_num: 'options.help_desk_kasko.devices_num',
      help_desk_kasko_enabled: 'options.help_desk_kasko.enabled',
      help_desk_kasko_name: 'options.help_desk_kasko.name',
      help_desk_kasko: 'options.help_desk_kasko',
      help_desk_mdm_channels: 'options.help_desk_mdm.channels',
      help_desk_mdm_devices_num: 'options.help_desk_mdm.devices_num',
      help_desk_mdm_enabled: 'options.help_desk_mdm.enabled',
      help_desk_mdm_mdm: 'options.help_desk_mdm.mdm',
      help_desk_mdm_name: 'options.help_desk_mdm.name',
      help_desk_mdm: 'options.help_desk_mdm',
      kasko_devices_brand: 'options.kasko.devices_brand',
      kasko_devices_model: 'options.kasko.devices_model',
      kasko_devices_num: 'options.kasko.devices_num',
      kasko_enabled: 'options.kasko.enabled',
      kasko_name: 'options.kasko.name',
      kasko_partner: 'options.kasko.partner',
      kasko_pool_coverage: 'options.kasko.pool_coverage',
      kasko: 'options.kasko',
      kitting_devices_brand: 'options.kitting.devices_brand',
      kitting_devices_model: 'options.kitting.devices_model',
      kitting_devices_num: 'options.kitting.devices_num',
      kitting_enabled: 'options.kitting.enabled',
      kitting_name: 'options.kitting.name',
      kitting_partner: 'options.kitting.partner',
      kitting_pool_coverage: 'options.kitting.pool_coverage',
      kitting: 'options.kitting',
      notes: 'options.notes',
      pin: 'options.pin',
      scorta_calda_devices_brand: 'options.scorta_calda.devices_brand',
      scorta_calda_devices_model: 'options.scorta_calda.devices_model',
      scorta_calda_devices_num: 'options.scorta_calda.devices_num',
      scorta_calda_enabled: 'options.scorta_calda.enabled',
      scorta_calda_name: 'options.scorta_calda.name',
      scorta_calda: 'options.scorta_calda',
      time_range: 'options.time_range',
      weekdays: 'options.weekdays',
    }),
    ...mapState('plansSubscriptions', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),

    expirationBridge: {
      get() {
        // When retrieving if the expiration is not set, retrieve default option
        if (!this.date_expiration) {
          return this.expirationOptions.find((el) => el.default).value
          // If the expiration is set understand whether is a predef option or arbitrary one
        } else {
          return (
            this.expirationOptions.find(
              (el) => el.value === this.date_expiration
            )?.value || 'other'
          )
        }
      },
      async set(value) {
        if (value !== 'other') this.date_expiration = value
      },
    },

    expirationOptions() {
      let start = this.$moment.utc()
      if (this.date_start) start = this.$moment.utc(this.date_start)
      return [
        {
          text: '12 Mesi',
          value: start
            .clone()
            .add(1, 'y')
            .format('YYYY-MM-DD'),
        },
        {
          text: '24 Mesi',
          value: start
            .clone()
            .add(2, 'y')
            .format('YYYY-MM-DD'),
          default: true,
        },
        {
          text: '36 Mesi',
          value: start
            .clone()
            .add(3, 'y')
            .format('YYYY-MM-DD'),
        },
        { text: 'Altro', value: 'other' },
      ]
    },
  },
  methods: {
    /*
    When changing the start value check if the user wanted to have an arbitrary value
    If not, set the expiration date
    */
    dateStartChange(value) {
      const hasSelectedOtherIntentionally = this.expirationBridge === 'other'
      const indexOfCurrentSelection = this.expirationOptions.findIndex(
        (el) => el.value === this.expirationBridge
      )

      if (value) {
        this.date_start = value
        if (!hasSelectedOtherIntentionally) {
          this.expirationBridge = this.expirationOptions[
            indexOfCurrentSelection
          ].value
        }
      }
    },
    ...mapMutations('plansSubscriptions', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('plansSubscriptions', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
