<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <h4 v-if="title" class="title mb-3">{{ title }}</h4>

      <v-divider v-if="title" class="mb-3" />

      <slot />

      <v-divider class="mb-3" />

      <v-alert v-if="invalid" type="info">
        Per procedere all'invio, assicurati di aver riempito tutti i campi
        obbligatori.
      </v-alert>

      <v-btn
        type="submit"
        :color="invalid ? 'red' : 'green'"
        class="mr-3"
        depressed
        tile
        :dark="!disabled"
        :disabled="disabled"
      >
        <v-icon left>mdi-content-save-edit</v-icon>
        {{ sendText }}
      </v-btn>

      <v-btn v-if="!hideReset" depressed tile @click="clear">
        Resetta
      </v-btn>

      <slot name="actions" />
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'Form',
  props: {
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideReset: {
      type: Boolean,
      default: false,
    },
    sendText: {
      type: String,
      default: 'Invia',
    },
  },
  methods: {
    onSubmit() {
      if (this.disabled) return
      this.$emit('submit')
    },
    clear() {
      this.$refs.observer.reset()
      this.$emit('reset')
    },
  },
}
</script>

<style scoped></style>
