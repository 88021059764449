<template>
  <FormItem
    :deletable-chips="multi"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    :label="label || name"
    :name="name"
    :persistent-hint="persistentHint"
    :rules="rules"
    :type="multi ? 'autocomplete' : 'select'"
    :value="value"
    :values="list"
    @input="onInput"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { debounce } from 'lodash'

export default {
  name: 'FormPlans',
  components: { FormItem },
  props: {
    cols: {
      default: 12,
      type: Number,
    },
    sm: {
      default: null,
      type: Number,
    },
    md: {
      default: 3,
      type: Number,
    },
    lg: {
      default: null,
      type: Number,
    },
    xl: {
      default: null,
      type: Number,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    icon: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Piano',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  computed: {
    ...mapState('plans', ['list']),
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('plans', {
      getData: 'getDropdownList',
    }),
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
