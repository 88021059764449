<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      {{ label || name }}
      <v-range-slider
        :append-icon="icon"
        :error-messages="errors"
        :hint="hint"
        :max="max"
        :min="min"
        :persistent-hint="persistentHint"
        :value="value"
        class="align-center mt-2"
        hide-details
        @change="onInput"
      >
        <template v-slot:prepend>{{ value[0] }}:00</template>
        <template v-slot:append>{{ value[1] }}:00</template>
      </v-range-slider>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'FormTimeRange',
  props: {
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: 6,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    icon: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    name: {
      default: 'Orari',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    /* Value is required and needs to be a [x, y] array */
    value: {
      default: null,
      required: true,
      type: [Array],
    },
  },
  data: () => ({
    min: 0,
    max: 24,
  }),
  methods: {
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
