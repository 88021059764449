<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="select"
        :chips="multi"
        :clearable="clearable"
        :deletable-chips="multi"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :filter="customFilter"
        :hint="hint"
        :items="list"
        :label="label || name"
        :loading="loading"
        :multiple="multi"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :search-input.sync="search"
        :small-chips="multi"
        hide-details
        filled
        @input="onInput"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Non è stato trovato nessun utente.
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            color="indigo"
            class="font-weight-light white--text"
          >
            {{ item.last_name.charAt(0) }}{{ item.first_name.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.last_name }} {{ item.first_name }}
            </v-list-item-title>
            <v-list-item-subtitle v-text="item.email" />
            <v-list-item-subtitle v-text="item.mobile_phone_number" />
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { debounce, matchesProperty } from 'lodash'
import { customFilterFromProps } from '@/src/utils/helper-functions'

export default {
  name: 'FormCustomersUsers',
  components: {},
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 2,
    },
    xl: {
      type: [String, Number],
      default: 2,
    },
    customer_id: {
      required: false,
      type: [String, Array, Object],
    },
    customer_user_id: {
      default: null,
      type: String,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Utenti Clienti',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    search: null,
    select: null,
  }),
  computed: {
    ...mapState('customersUsers', ['list', 'loading']),
  },
  watch: {
    customer_id: {
      immediate: true,
      handler: 'prefill',
    },
    customer_user_id: {
      immediate: true,
      handler: 'prefill',
    },
    search(val) {
      if (val) {
        if (val !== this.select) {
          /**
           * The component emits a last search when the wanted element is clicked form the dropdown,
           * this prevents a search that would return an empty list
           */
          if (this.list.some(matchesProperty('text', val))) return
          // This prevents the component form ignoring changes injected from outside
          if (this.select !== this.value || !this.customer_id) {
            this.querySelections(val)
          }
        }
      }
    },
  },
  beforeDestroy() {
    this.clearList()
  },
  methods: {
    ...mapActions('customersUsers', {
      searchUsers: 'getDropdownList',
    }),
    ...mapMutations('customersUsers', {
      clearList: 'RESET_LIST',
    }),
    async prefill() {
      if (!this.customer_id) return this.clearList()

      const filters = {
        customer_id: this.customer_id,
      }
      if (this.customer_user_id) filters.id = this.customer_user_id

      await this.searchUsers({
        filters,
      })

      this.select = this.customer_user_id
    },
    customFilter: customFilterFromProps([
      'last_name',
      'first_name',
      'email',
      'mobile_phone_number',
    ]),
    async querySelections(query) {
      if (query.length < 3) return

      await this.searchUsers({
        filters: {
          query,
          customer_id: this.customer_id,
        },
      })
    },
    onInput: debounce(function(value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
