<template>
  <FormItem
    :clearable="clearable"
    :deletable-chips="multi"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    :label="label || name"
    :name="name"
    :persistent-hint="persistentHint"
    :rules="rules"
    :type="multi ? 'autocomplete' : 'select'"
    :value="value"
    :values="mdms"
    @input="onInput"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { debounce } from 'lodash'

export default {
  name: 'FormMDM',
  components: { FormItem },
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: 3,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'MDM In uso',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    mdms: ['MobileIron', 'Airwatch', 'Knox', 'InTune', 'Altri'],
  }),
  methods: {
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
