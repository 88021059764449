<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    {{ label || name }}
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-row>
        <v-col v-for="day in days" :key="day.idx">
          <v-checkbox
            :dense="dense"
            :error-messages="errors"
            :hide-details="true"
            :input-value="value"
            :label="day.label"
            :value="day.idx"
            persistent-hint
            @change="onInput"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="error in errors" :key="error" :cols="12">
          <span class="red--text">{{ error }}</span>
        </v-col>
      </v-row>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'FormWeekDays',
  props: {
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: 6,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    icon: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    name: {
      default: 'Giorni settimana',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    model: null,
    days: [
      { idx: 0, label: 'L' },
      { idx: 1, label: 'M' },
      { idx: 2, label: 'M' },
      { idx: 3, label: 'G' },
      { idx: 4, label: 'V' },
      { idx: 5, label: 'S' },
      { idx: 6, label: 'D' },
    ],
  }),
  methods: {
    onInput: debounce(function (value) {
      this.$emit('input', value.sort())
    }, 250),
  },
}
</script>
