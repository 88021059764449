var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[(!_vm.activeOptions.length)?_c('v-card-text',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mb-3",attrs:{"x-large":""}},[_vm._v("mdi-playlist-remove")]),_vm._v(" Non ci sono opzioni attive per questa sottoscrizione. ")],1):_vm._e(),_c('v-list',[(
          (_vm.item.max_ticket_per_cycle || _vm.item.max_ticket_per_duration) &&
            (_vm.item.max_ticket_per_cycle > -1 ||
              _vm.item.max_ticket_per_duration > -1)
        )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[(
                _vm.item.max_ticket_per_cycle && _vm.item.max_ticket_per_cycle > -1
              )?_c('strong',[_vm._v(" "+_vm._s(_vm.item.max_ticket_per_cycle)+" per ciclo fatturazione ")]):_vm._e(),(
                _vm.item.max_ticket_per_duration &&
                  _vm.item.max_ticket_per_duration > -1
              )?_c('strong',[_vm._v(" "+_vm._s(_vm.item.max_ticket_per_duration)+" per durata totale ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v(" Numero massimo ticket ")])],1)],1):_vm._e(),_vm._l((_vm.activeOptions),function(option){return _c('v-list-item',{key:option.name},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(option.name))])]),(option.devices_num)?_c('v-list-item-subtitle',[_vm._v(" Dispositivi: "),_c('strong',[_vm._v(_vm._s(option.devices_num))])]):_vm._e(),(option.mdm)?_c('v-list-item-subtitle',[_vm._v(" MDM in Uso: "),_c('strong',[_vm._v(_vm._s(option.mdm))])]):_vm._e(),(option.devices_brand)?_c('v-list-item-subtitle',[_vm._v(" Modello: "),_c('strong',[_vm._v(" "+_vm._s(option.devices_brand)+" "+_vm._s(option.devices_model)+" ")])]):_vm._e(),(option.app)?_c('v-list-item-subtitle',[_vm._v(" Applicativo: "),_c('strong',[_vm._v(_vm._s(option.app))])]):_vm._e(),(option.partner)?_c('v-list-item-subtitle',[_vm._v(" Partner: "),_c('strong',[_vm._v(_vm._s(option.partner))])]):_vm._e()],1)],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Chiudi ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }